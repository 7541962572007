var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            icon: "",
                            color: "#0F87C9",
                            id: "goBackButton",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.goBack()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("fas fa-arrow-left")])],
                        1
                      ),
                      _c(
                        "v-card",
                        {
                          staticClass:
                            "text-sm-center avatar white--text mt-1 mx-3",
                          attrs: {
                            flat: "",
                            color: _vm.avatarColor(_vm.displayName),
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.displayName.substring(0, 2).toUpperCase()
                            ) + " "
                          ),
                        ]
                      ),
                      _c("v-toolbar-title", [_vm._v(_vm._s(_vm.displayName))]),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [_c("v-subheader", [_vm._v("Nom du groupe")])],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "8" } },
                            [
                              _c("v-text-field", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.displayName,
                                  callback: function ($$v) {
                                    _vm.displayName = $$v
                                  },
                                  expression: "displayName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-subheader", [
                                _vm._v("Description du groupe"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "8" } },
                            [
                              _c("v-text-field", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.description,
                                  callback: function ($$v) {
                                    _vm.description = $$v
                                  },
                                  expression: "description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "v-alert",
                                {
                                  attrs: {
                                    density: "compact",
                                    type: "warning",
                                    icon: "fas fa-user-lock",
                                    title: "coucou",
                                    text: "",
                                  },
                                  model: {
                                    value: _vm.ADMMembersOnly,
                                    callback: function ($$v) {
                                      _vm.ADMMembersOnly = $$v
                                    },
                                    expression: "ADMMembersOnly",
                                  },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      '"Ce groupe porte des privilèges élevés. Il ne doit comporter que des comptes ADM."'
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v("Propriétaires")]),
                  _c("OwnedGroupsEdit", {
                    attrs: {
                      listOwnersAndMembers: _vm.listOwnersAndMembers,
                      loading: this.isLoadingMembersAndOwners,
                      shouldLoadMore: this.shouldLoadMore,
                      ADMMembersOnly: _vm.ADMMembersOnly,
                    },
                    on: {
                      loadMore: _vm.loadMore,
                      addMember: _vm.addMember,
                      deleteMember: _vm.deleteMember,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: _vm.y === "bottom", timeout: _vm.timeout },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(this.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { color: "#0F87C9", text: "" },
              on: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("Fermer")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }