var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { id: "app" } },
    [
      _c("AppBarNavigation"),
      _c("v-main", [_c("v-container", [_c("router-view")], 1)], 1),
      _c("GlobalSnackbar"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }