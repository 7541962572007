var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("v-img", {
        attrs: {
          src: require("../assets/azure/10221-icon-service-Azure-Active-Directory.svg"),
          height: "200",
          contain: "",
        },
      }),
      _c(
        "v-alert",
        { attrs: { prominent: "", text: "", type: "info" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c("v-col", { staticClass: "text-center" }, [
                _c("div", { staticClass: "text-h6" }, [
                  _vm._v(" Bienvenue dans e.SNCF Azure AD Manager "),
                ]),
                _c("span", [
                  _vm._v(
                    " Ce site va vous permettre de gérer vos objets Azure AD. "
                  ),
                  _c("br"),
                  _vm._v(" Pour commencez, rendez-vous dans le menu "),
                  _c("span", { staticClass: "fa-stack" }, [
                    _c("i", { staticClass: "fas fa-circle fa-stack-2x" }),
                    _c("i", {
                      staticClass: "fas fa-bars fa-stack-1x fa-inverse",
                    }),
                  ]),
                  _vm._v("à gauche. "),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }