import { render, staticRenderFns } from "./owned-groups-list-view.vue?vue&type=template&id=317f2473&scoped=true"
import script from "./owned-groups-list-view.vue?vue&type=script&lang=js"
export * from "./owned-groups-list-view.vue?vue&type=script&lang=js"
import style0 from "./owned-groups-list-view.vue?vue&type=style&index=0&id=317f2473&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "317f2473",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/zure-ad-manager-snapshot_develop/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('317f2473')) {
      api.createRecord('317f2473', component.options)
    } else {
      api.reload('317f2473', component.options)
    }
    module.hot.accept("./owned-groups-list-view.vue?vue&type=template&id=317f2473&scoped=true", function () {
      api.rerender('317f2473', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/owned-groups-list-view.vue"
export default component.exports