var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c(
        "v-alert",
        { attrs: { prominent: "", type: "warning" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c("v-col", { staticClass: "grow" }, [
                _vm._v(" Vous n'êtes pas authentifié. "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }