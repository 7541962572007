var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            icon: "",
                            color: "#0F87C9",
                            id: "goBackButton",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.goBack()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("fas fa-arrow-left")])],
                        1
                      ),
                      _c(
                        "v-card",
                        {
                          staticClass:
                            "text-sm-center avatar white--text mt-1 mx-3",
                          attrs: {
                            flat: "",
                            color: _vm.avatarColor(_vm.displayName),
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.displayName.substring(0, 2).toUpperCase()
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-toolbar-title", [_vm._v(_vm._s(_vm.displayName))]),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [_c("v-subheader", [_vm._v("Display Name")])],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "8" } },
                            [
                              _c("v-text-field", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.displayName,
                                  callback: function ($$v) {
                                    _vm.displayName = $$v
                                  },
                                  expression: "displayName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [_c("v-subheader", [_vm._v("Object Id")])],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "8" } },
                            [
                              _c("v-text-field", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.id,
                                  callback: function ($$v) {
                                    _vm.id = $$v
                                  },
                                  expression: "id",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-subheader", [
                                _vm._v("Application (client) Id"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "8" } },
                            [
                              _c("v-text-field", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.appId,
                                  callback: function ($$v) {
                                    _vm.appId = $$v
                                  },
                                  expression: "appId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v("Propriétaires")]),
                  _c("AppRegistrationEditOwners", {
                    attrs: {
                      listOwners: _vm.listOwners,
                      loading: this.isLoadingOwners,
                      shouldLoadMore: this.shouldLoadMore,
                    },
                    on: {
                      loadMore: _vm.loadMore,
                      addOwner: _vm.addOwner,
                      deleteOwner: _vm.deleteOwner,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: _vm.y === "bottom", timeout: _vm.timeout },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(this.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { color: "#0F87C9", text: "" },
              on: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("Fermer")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }