var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-app-bar",
        {
          class: { devel: _vm.env != "production" },
          attrs: {
            dark: "",
            flat: "",
            height: "60px",
            app: "",
            id: "header-app-bar",
          },
        },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function ($event) {
                _vm.drawer = !_vm.drawer
              },
            },
          }),
          _c("router-link", { attrs: { to: "/" } }, [
            _c("img", {
              staticClass: "mt-1",
              attrs: {
                src: require("../../assets/logo.png"),
                id: "Logo",
                alt: "logo sncf",
              },
            }),
          ]),
          _c("v-toolbar-title", { attrs: { id: "appTitle" } }, [
            _vm._v(_vm._s(_vm.appTitle)),
            _vm.env != "production"
              ? _c("span", { staticClass: "env" }, [_vm._v(_vm._s(_vm.env))])
              : _vm._e(),
          ]),
          _c("v-spacer"),
          _c("v-toolbar-items", [
            _c("span", { staticClass: "mx-2", attrs: { id: "userInfo" } }, [
              _vm._v("Bienvenue, " + _vm._s(_vm.account_name)),
            ]),
          ]),
          _c(
            "v-avatar",
            [
              _vm.photoUrl != undefined && _vm.photoUrl.length > 0
                ? _c("img", {
                    attrs: { src: _vm.photoUrl, alt: _vm.account_name },
                  })
                : _c("v-icon", [_vm._v("fa-user")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: { app: "", absolute: "", temporary: "" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-list",
            { attrs: { nav: "", dense: "" } },
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-avatar",
                    [
                      _c("v-img", {
                        attrs: {
                          src: require("../../assets/azure/10221-icon-service-Azure-Active-Directory.svg"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [_vm._v(_vm._s(_vm.appTitle))]),
                      _c("v-list-item-subtitle", [
                        _vm._v(_vm._s(_vm.packageVersion)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list-group",
                {
                  attrs: { "no-action": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function () {
                        return [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-img", {
                                attrs: {
                                  src: require("../../assets/azure/10223-icon-service-Groups.svg"),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Groupes")])],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                _vm._l(_vm.groupMenuEntries, function ([title, route], i) {
                  return _c(
                    "v-list-item",
                    {
                      key: i,
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({ path: route })
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", {
                        domProps: { textContent: _vm._s(title) },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        path: "/ownedappregistrations",
                      })
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-img", {
                        attrs: {
                          src: require("../../assets/azure/10232-icon-service-App-Registrations.svg"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v("App Registration")]),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.doShowDialog = true
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", { attrs: { color: "blue" } }, [
                        _vm._v(" fas fa-info-circle"),
                      ]),
                    ],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v("A propos")]),
                  _c("AboutDialog", {
                    attrs: {
                      dialog: _vm.doShowDialog,
                      "app-title": _vm.appTitle,
                      "package-version": _vm.packageVersion,
                    },
                    on: {
                      close: function ($event) {
                        _vm.doShowDialog = false
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }