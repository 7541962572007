var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      staticStyle: {
        "font-size": "16px",
        "font-family": "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      },
      attrs: {
        timeout: _vm.snackbar.timeout,
        "multi-line": _vm.snackbar.multiline,
        color: _vm.snackbar.color,
        label: _vm.snackbar.color,
        value: _vm.snackbar.color,
        bottom: "",
      },
      model: {
        value: _vm.snackbar.visible,
        callback: function ($$v) {
          _vm.$set(_vm.snackbar, "visible", $$v)
        },
        expression: "snackbar.visible",
      },
    },
    [
      _vm._v(" " + _vm._s(_vm.snackbar.text) + " "),
      _c(
        "v-btn",
        {
          attrs: {
            color: _vm.snackbar.color === "error" ? "#fff" : "#0F87C9",
            text: "",
            dark: "",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.closeSnackbar.apply(null, arguments)
            },
          },
        },
        [_vm._v("Fermer")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }