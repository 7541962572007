var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "300" },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "primary", dark: "" } },
        [
          _c(
            "v-card-text",
            [
              _vm._v(" " + _vm._s(_vm.text) + " "),
              _c("v-progress-linear", {
                staticClass: "mb-0",
                attrs: { indeterminate: "", color: "white" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }