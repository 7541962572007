var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.showDialog, width: "500" },
      on: {
        "click:outside": function ($event) {
          return _vm.close()
        },
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "text-h5" },
            [
              _c("v-icon", { attrs: { left: "", color: "blue" } }, [
                _vm._v(" fas fa-info-circle"),
              ]),
              _vm._v(" A propos "),
            ],
            1
          ),
          _c("v-divider"),
          _c("v-img", {
            attrs: {
              src: require("../assets/azure/10221-icon-service-Azure-Active-Directory.svg"),
              height: "100",
              contain: "",
            },
          }),
          _c("v-card-subtitle", { staticClass: "text-center" }, [
            _vm._v(" " + _vm._s(_vm.appTitle)),
            _c("br"),
            _vm._v(_vm._s(_vm.packageVersion) + " "),
          ]),
          _c(
            "v-card-text",
            { staticClass: "text-center" },
            [
              _vm._v(
                " Cette application web vous permet de consulter et de gérer les objets Azure AD auxquels vous êtes lié. Soit en tant que propriétaire, soit en tant que membre. "
              ),
              _c("br"),
              _c("br"),
              _vm._v(
                " Elle utilise votre identité pour interragir avec l'api Microsoft Graph. "
              ),
              _c("br"),
              _c("br"),
              _c("v-img", {
                attrs: {
                  src: require("../assets/Logo ASO.svg"),
                  height: "70",
                  contain: "",
                },
              }),
              _c("p", { staticClass: "font-italic" }, [
                _vm._v("Made with ❤️ by DEA ASO Cloud - Core Team Azure"),
              ]),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v(" Fermer ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }