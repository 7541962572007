var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { width: "604", app: "", right: "", temporary: "" },
          model: {
            value: _vm.addMembersDialog,
            callback: function ($$v) {
              _vm.addMembersDialog = $$v
            },
            expression: "addMembersDialog",
          },
        },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c("v-text-field", {
                attrs: {
                  placeholder:
                    "Ajouter ou rechercher un propriétaire (nom ou email)",
                  "single-line": "",
                  "hide-details": "",
                  "prepend-icon": "fas fa-search",
                  outline: "",
                  "hide-no-data": "",
                },
                on: { keyup: _vm.searchUsers },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _c("v-progress-circular", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loader,
                    expression: "loader",
                  },
                ],
                staticStyle: {
                  position: "relative",
                  top: "30px",
                  right: "-250px",
                },
                attrs: { color: "secondary", size: 35, indeterminate: "" },
              }),
              _vm.displayResults && _vm.notFound && !_vm.loader
                ? _c(
                    "v-list",
                    {
                      staticStyle: {
                        width: "604px",
                        top: "56px",
                        right: "20px",
                        height: "70px",
                        "vertical-align": "middle",
                      },
                      attrs: { "two-line": "", subheader: "" },
                    },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            {
                              staticStyle: { "align-items": "center" },
                              attrs: { color: "secondary" },
                            },
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass: "accent--text",
                                  staticStyle: { "text-align": "center" },
                                },
                                [
                                  _vm._v(
                                    "Aucun élément correspondant à votre recherche n'a été trouvé"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.displayResults & !_vm.notFound && !_vm.loader
                ? _c(
                    "v-list",
                    {
                      staticClass: "mb-2",
                      staticStyle: {
                        width: "604px",
                        top: "56px",
                        overflow: "auto",
                        "max-height": "400px",
                      },
                      attrs: { "two-line": "", subheader: "" },
                    },
                    [
                      _vm.subheaderUtil
                        ? _c("v-subheader", { staticClass: "subheader" }, [
                            _vm._v("Propriétaire de l'App Registration"),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.userList, function (user) {
                        return _c(
                          "v-list-item",
                          { key: user.id },
                          [
                            _c(
                              "v-list-item-avatar",
                              [
                                _c(
                                  "v-avatar",
                                  {
                                    staticClass: "userImage white--text",
                                    attrs: {
                                      dark: "",
                                      flat: "",
                                      color: "primary",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          user.displayName
                                            .substring(0, 2)
                                            .toUpperCase()
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(user.displayName)),
                                ]),
                                user.type === "#microsoft.graph.user"
                                  ? _c("v-list-item-subtitle", [
                                      _vm._v(_vm._s(user.mail)),
                                    ])
                                  : _vm._e(),
                                user.type === "#microsoft.graph.group"
                                  ? _c("v-list-item-subtitle", [
                                      _vm._v("Groupe"),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-action",
                              [
                                _c("v-icon", { attrs: { color: "primary" } }, [
                                  _vm._v("fas fa-link"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _c("v-divider"),
                      _vm.subheaderNotUtil
                        ? _c("v-subheader", { staticClass: "subheader" }, [
                            _vm._v("Non propriétaire"),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.notUserList, function (user) {
                        return _c(
                          "v-list-item",
                          {
                            key: user.id,
                            on: {
                              click: function ($event) {
                                return _vm.addOwner(user)
                              },
                            },
                          },
                          [
                            _c(
                              "v-list-item-avatar",
                              [
                                _c(
                                  "v-avatar",
                                  {
                                    staticClass: "userImage white--text",
                                    attrs: {
                                      dark: "",
                                      flat: "",
                                      color: "primary",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          user.displayName
                                            .substring(0, 2)
                                            .toUpperCase()
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(user.displayName)),
                                ]),
                                user.type === "#microsoft.graph.user"
                                  ? _c("v-list-item-subtitle", [
                                      _vm._v(_vm._s(user.mail)),
                                    ])
                                  : _vm._e(),
                                user.type === "#microsoft.graph.group"
                                  ? _c("v-list-item-subtitle", [
                                      _vm._v("Groupe"),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-action",
                              [
                                _c("v-icon", { attrs: { color: "primary" } }, [
                                  _vm._v("fas fa-plus"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-toolbar",
        { attrs: { flat: "" } },
        [
          _c("v-checkbox", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.listOwners.length > 0,
                expression: "listOwners.length > 0",
              },
            ],
            staticClass: "mt-2",
            attrs: {
              color: "primary",
              indeterminate: _vm.allChecked,
              label: "Tout sélectionner",
            },
            on: { change: _vm.checkAllToggle },
            model: {
              value: _vm.allChecked,
              callback: function ($$v) {
                _vm.allChecked = $$v
              },
              expression: "allChecked",
            },
          }),
          _c(
            "v-btn",
            {
              staticClass: "ml-16",
              attrs: { color: "primary" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.addMembersDialog = !_vm.addMembersDialog
                },
              },
            },
            [
              _vm._v(" Ajouter "),
              _c("v-icon", { attrs: { dark: "", right: "" } }, [
                _vm._v(" fa-plus fas "),
              ]),
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.listOwners.length > 0,
                  expression: "listOwners.length > 0",
                },
              ],
              staticClass: "ml-4",
              attrs: {
                color: "secondary",
                disabled: _vm.checkedOwners.length === 0,
              },
              on: { click: _vm.showBulkDeleteDialog },
            },
            [
              _vm._v("Supprimer la sélection "),
              _c("v-icon", { attrs: { dark: "", right: "" } }, [
                _vm._v(" fa-times fas "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-list",
        [
          _vm._l(_vm.listOwners, function (owner, index) {
            return [
              _c(
                "v-list-item",
                { key: owner.id },
                [
                  _c(
                    "v-list-item-action",
                    [
                      _c("v-checkbox", {
                        attrs: {
                          disabled: owner.displayName.includes("PAM_RECONCIL_"),
                          value: owner.id,
                        },
                        on: { change: _vm.checkedOwnersChanged },
                        model: {
                          value: _vm.checkedOwners,
                          callback: function ($$v) {
                            _vm.checkedOwners = $$v
                          },
                          expression: "checkedOwners",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-list-item-avatar", {
                    staticClass: "white--text mr-8",
                    staticStyle: { "justify-content": "center" },
                    attrs: { color: _vm.avatarColor(owner.displayName) },
                    domProps: {
                      textContent: _vm._s(
                        owner.displayName.substring(0, 2).toUpperCase()
                      ),
                    },
                  }),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", {
                        domProps: { textContent: _vm._s(owner.displayName) },
                      }),
                      _c("v-list-item-subtitle", {
                        domProps: { textContent: _vm._s(owner.mail) },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            fab: "",
                            icon: "",
                            small: "",
                            color: "secondary",
                            disabled:
                              owner.displayName.includes("PAM_RECONCIL_"),
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.showdeleteOwnerDialog(owner)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("fas fa-times")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              index < _vm.listOwners.length - 1
                ? _c("v-divider", { key: index })
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("v-chip", { attrs: { label: "", color: "primary" } }, [
            _vm._v(" " + _vm._s(this.countOwners) + " "),
          ]),
        ],
        1
      ),
      !this.loading && this.shouldLoadMore
        ? _c(
            "v-card",
            {
              staticClass: "borderList",
              staticStyle: { display: "flex", "justify-content": "center" },
              attrs: { flat: "" },
              on: { click: _vm.loadMoreItem },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "padding-top": "20px",
                    color: "#0f87c9",
                    cursor: "pointer",
                  },
                },
                [_vm._v("Charger plus ...")]
              ),
            ]
          )
        : _vm._e(),
      this.loading
        ? _c(
            "v-card",
            {
              staticClass: "borderList",
              staticStyle: {
                display: "flex",
                padding: "30px 0",
                "justify-content": "center",
                "align-items": "center",
              },
              attrs: { flat: "" },
            },
            [
              _c("v-progress-circular", {
                staticStyle: { color: "#0F87C9", "background-color": "white" },
                attrs: { size: 35, indeterminate: "" },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { width: "660" },
          model: {
            value: _vm.deleteOwnerDialogVisible,
            callback: function ($$v) {
              _vm.deleteOwnerDialogVisible = $$v
            },
            expression: "deleteOwnerDialogVisible",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", { staticClass: "thetitle text-xs-center" }, [
                _vm._v("Supprimer un propriétaire"),
              ]),
              _c("v-card-text", { staticClass: "thecontent text-xs-center" }, [
                _vm._v(" Voulez-vous vraiment Supprimer ce propriétaire ? "),
                _c("br"),
                _c("strong", [_vm._v("Cette action est irréversible.")]),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "actionButton",
                      staticStyle: { margin: "10px" },
                      attrs: { outlined: "", color: "#D52C1E" },
                      on: {
                        click: function ($event) {
                          _vm.deleteOwnerDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("Annuler")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "actionButton",
                      staticStyle: { margin: "10px" },
                      attrs: { text: "", id: "confirmDelete" },
                      on: { click: _vm.deleteOwner },
                    },
                    [_vm._v("Supprimer le propriétaire")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "660" },
          model: {
            value: _vm.bulkDeleteDialog,
            callback: function ($$v) {
              _vm.bulkDeleteDialog = $$v
            },
            expression: "bulkDeleteDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "thetitle text-xs-center" }, [
                _vm._v("Suppression de plusieurs propriétaires"),
              ]),
              _c("v-card-text", { staticClass: "thecontent text-xs-center" }, [
                _vm._v(" Voulez-vous vraiment supprimer ces propriétaires ? "),
                _c("br"),
                _c("strong", [_vm._v("Cette action est irréversible.")]),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.bulkDeleteDialog = false
                        },
                      },
                    },
                    [_vm._v("Annuler")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { id: "confirmDelete", color: "accent" },
                      on: { click: _vm.deleteSelected },
                    },
                    [
                      _vm._v("Supprimer la sélection "),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v(" fa-times "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DialogLoader", { ref: "dialogLoader" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }