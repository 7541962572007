var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("meta", { attrs: { charset: "utf-8" } }),
      _c(
        "v-toolbar",
        { attrs: { flat: "", light: "", color: "white", height: "100" } },
        [
          _c("img", {
            attrs: {
              alt: "",
              src: require("../assets/azure/10223-icon-service-Groups.svg"),
              height: "70%",
            },
          }),
          _c(
            "v-toolbar-title",
            {
              staticStyle: { display: "initial", "margin-left": "120px" },
              attrs: { id: "mesGroupes" },
            },
            [_vm._v("Vous êtes membre des groupes Azure suivants")]
          ),
        ],
        1
      ),
      _c(
        "v-toolbar",
        {
          attrs: { dark: "", color: _vm.search.length ? "#4DADE3" : "#008AD7" },
        },
        [
          _c(
            "v-text-field",
            {
              staticClass: "custom-label-color",
              attrs: {
                flat: "",
                "single-line": "",
                solo: "",
                placeholder: "Rechercher un groupe",
                "hide-details": "",
                "background-color": "transparent",
              },
              on: { input: _vm.GetDataOnSearch },
              model: {
                value: _vm.search,
                callback: function ($$v) {
                  _vm.search = $$v
                },
                expression: "search",
              },
            },
            [
              _c(
                "v-icon",
                { attrs: { slot: "prepend", color: "white" }, slot: "prepend" },
                [_vm._v("fas fa-search")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        {
          staticStyle: {
            "background-color": "#F5F5F5",
            height: "100%",
            padding: "0",
            position: "relative",
          },
          attrs: { fluid: "" },
        },
        [
          _vm.isLoading
            ? _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-progress-circular", {
                        staticStyle: {
                          position: "absolute",
                          left: "50%",
                          top: "30%",
                        },
                        attrs: {
                          size: 50,
                          color: "#0F87C9",
                          indeterminate: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.noGroup && !_vm.nothing
            ? _c(
                "v-container",
                [
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        prominent: "",
                        text: "",
                        type: "info",
                        icon: "fas fa-users",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c("v-col", { staticClass: "text-center" }, [
                            _c("div", { staticClass: "text-h6" }, [
                              _vm._v(" Aucun groupe trouvé "),
                            ]),
                            _c("span", [
                              _vm._v(
                                " Vous n'êtes membre d'aucun groupe Azure AD. "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.isLoading && _vm.nothing
            ? _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    {
                      staticStyle: {
                        color: "red",
                        "background-color": "#F4F4F4",
                      },
                    },
                    [
                      _vm._v(
                        "Aucun élément correspondant à votre recherche n'a été trouvé"
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.isLoading
            ? _c(
                "v-container",
                { staticStyle: { width: "1063px" } },
                [
                  !_vm.noGroup && !_vm.nothing
                    ? _c(
                        "v-row",
                        {
                          staticClass: "tableau",
                          attrs: { "justify-center": "", "align-center": "" },
                        },
                        [
                          _c("v-col", { attrs: { cols: "1" } }),
                          _c(
                            "v-col",
                            { attrs: { cols: "3", "text-align-left": "" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "px-0 leftAlign" },
                                    [_vm._v("Nom du groupe")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "8" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "px-0 leftAlign" },
                                    [_vm._v("Description")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    { attrs: { "justify-center": "", "align-center": "" } },
                    [
                      _c(
                        "v-container",
                        {
                          staticClass: "scroll-y",
                          attrs: { fluid: "", id: "scroll-target" },
                        },
                        [
                          _c(
                            "v-row",
                            {
                              directives: [
                                {
                                  name: "scroll",
                                  rawName: "v-scroll:#scroll-target",
                                  value: _vm.onScroll,
                                  expression: "onScroll",
                                  arg: "#scroll-target",
                                },
                              ],
                              attrs: { column: "" },
                            },
                            _vm._l(_vm.entries, function (entry) {
                              return _c(
                                "v-col",
                                { key: entry.id, attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "nav-link mb-2" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "1",
                                                "text-xs-center": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  staticClass:
                                                    "text-sm-center groupImage white--text mt-2 ml-3",
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  attrs: {
                                                    flat: "",
                                                    color: _vm.avatarColor(
                                                      entry.displayName
                                                    ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(entry.bigramme) + " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "3" } },
                                            [
                                              _c(
                                                "v-card",
                                                { attrs: { flat: "" } },
                                                [
                                                  _c(
                                                    "v-card-text",
                                                    {
                                                      staticClass:
                                                        "px-1 leftAlign",
                                                      attrs: {
                                                        "justify-left": "",
                                                        "align-left": "",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          entry.displayName
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "8" } },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  attrs: { flat: "" },
                                                },
                                                [
                                                  _c(
                                                    "v-card-text",
                                                    {
                                                      staticClass:
                                                        "px-1 leftAlign",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          entry.description
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }