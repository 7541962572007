var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("meta", { attrs: { charset: "utf-8" } }),
      _c(
        "v-toolbar",
        { attrs: { flat: "", light: "", color: "white", height: "100" } },
        [
          _c("img", {
            attrs: {
              alt: "",
              src: require("../assets/azure/10223-icon-service-Groups.svg"),
              height: "70%",
            },
          }),
          _c(
            "v-toolbar-title",
            {
              staticStyle: { display: "initial", "margin-left": "120px" },
              attrs: { id: "mesGroupes" },
            },
            [_vm._v("Vous êtes propriétaire des groupes Azure suivants")]
          ),
        ],
        1
      ),
      _c(
        "v-toolbar",
        {
          attrs: { dark: "", color: _vm.search.length ? "#4DADE3" : "#008AD7" },
        },
        [
          _c(
            "v-text-field",
            {
              staticClass: "custom-label-color",
              attrs: {
                flat: "",
                "single-line": "",
                solo: "",
                placeholder: "Rechercher un groupe",
                "hide-details": "",
                "background-color": "transparent",
              },
              on: { input: _vm.GetDataOnSearch },
              model: {
                value: _vm.search,
                callback: function ($$v) {
                  _vm.search = $$v
                },
                expression: "search",
              },
            },
            [
              _c(
                "v-icon",
                { attrs: { slot: "prepend", color: "white" }, slot: "prepend" },
                [_vm._v("fas fa-search")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        {
          staticStyle: {
            "background-color": "#F5F5F5",
            height: "100%",
            padding: "0",
            position: "relative",
          },
          attrs: { fluid: "" },
        },
        [
          _vm.isLoading
            ? _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-progress-circular", {
                        staticStyle: {
                          position: "absolute",
                          left: "50%",
                          top: "30%",
                        },
                        attrs: {
                          size: 50,
                          color: "#0F87C9",
                          indeterminate: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.noGroup && !_vm.nothing
            ? _c(
                "v-container",
                [
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        prominent: "",
                        text: "",
                        type: "info",
                        icon: "fas fa-users",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c("v-col", { staticClass: "text-center" }, [
                            _c("div", { staticClass: "text-h6" }, [
                              _vm._v(" Aucun groupe trouvé "),
                            ]),
                            _c("span", [
                              _vm._v(
                                " Vous n'êtes propriétaire d'aucun groupe Azure AD. "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.isLoading && _vm.nothing
            ? _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    {
                      staticStyle: {
                        color: "red",
                        "background-color": "#F4F4F4",
                      },
                    },
                    [
                      _vm._v(
                        "Aucun élément correspondant à votre recherche n'a été trouvé"
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.isLoading
            ? _c(
                "v-list",
                [
                  _vm._l(_vm.entries, function (user, index) {
                    return [
                      _c(
                        "v-list-item",
                        {
                          key: user.id,
                          on: {
                            click: function ($event) {
                              return _vm.goTodetail(user)
                            },
                          },
                        },
                        [
                          _c("v-list-item-avatar", {
                            staticClass: "white--text mr-8",
                            staticStyle: { "justify-content": "center" },
                            attrs: { color: _vm.avatarColor(user.displayName) },
                            domProps: {
                              textContent: _vm._s(
                                user.displayName.substring(0, 2).toUpperCase()
                              ),
                            },
                          }),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                domProps: {
                                  textContent: _vm._s(user.displayName),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-subtitle", {
                                domProps: {
                                  textContent: _vm._s(user.description),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              user.ADMMembersOnly
                                ? _c(
                                    "v-list-item-icon",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "orange" } },
                                        [_vm._v("fas fa-user-lock")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              user.ADMMembersOnly
                                ? _c("v-list-item-subtitle", [
                                    _vm._v(
                                      "Groupe à privilège. Comptes ADM requis."
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      index < _vm.entries.length - 1
                        ? _c("v-divider", { key: index })
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }